var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.extendJulie,
                  },
                  on: { click: _vm.extendTicket },
                },
                [_vm._v(_vm._s(_vm.$t("extend")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "140",
                    disabled: !_vm.secondNoticeJulie,
                  },
                  on: { click: _vm.secondNoticeTicket },
                },
                [_vm._v(_vm._s(_vm.$t("secondNotice")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.retransmitJulie,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateTicketDetails("RetransmitTicket")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("retransmit")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.cancelJulie,
                  },
                  on: { click: _vm.cancelTicket },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.extendJulie,
                  },
                  on: { click: _vm.extendTicket },
                },
                [_vm._v(_vm._s(_vm.$t("extend")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "140",
                    disabled: !_vm.secondNoticeJulie,
                  },
                  on: { click: _vm.secondNoticeTicket },
                },
                [_vm._v(_vm._s(_vm.$t("secondNotice")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.retransmitJulie,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateTicketDetails("RetransmitTicket")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("retransmit")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.cancelJulie,
                  },
                  on: { click: _vm.cancelTicket },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ],
            1
          ),
      _vm.cancelTicketDialog
        ? _c("CancelTicketDialogJulie", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.cancelTicketDialog,
            },
            on: {
              cancelTicketSubmit: _vm.cancelTicketSubmit,
              closeDialog: _vm.closeCancelTicketDialog,
              cancelTicketSuccess: _vm.cancelTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.extendTicketDialog
        ? _c("ExtendTicketDialogJulie", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.extendTicketDialog,
            },
            on: {
              extendTicketSubmit: _vm.extendTicketSubmit,
              closeExtendDialog: _vm.closeExtendTicketDialog,
              extendTicketSuccess: _vm.extendTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.secondNoticeTicketDialog
        ? _c("SecondNoticeDialogJulie", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.secondNoticeTicketDialog,
            },
            on: {
              secondNoticeTicketSubmit: _vm.secondNoticeTicketSubmit,
              closeSecondNoticeTicketDialog: _vm.closeSecondNoticeTicketDialog,
              secondNoticeTicketSuccess: _vm.secondNoticeTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.isError
        ? _c("ErrorMessage", {
            attrs: {
              "error-code": _vm.errorCode,
              "error-message": _vm.errorMessage,
            },
            on: {
              ticketScreen: function ($event) {
                _vm.isError = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }