<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!extendJulie"
        @click="extendTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("extend") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="140"
        :disabled="!secondNoticeJulie"
        @click="secondNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("secondNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        @click="updateTicketDetails('RetransmitTicket')"
        :disabled="!retransmitJulie"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("retransmit") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelJulie"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!extendJulie"
        @click="extendTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("extend") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="140"
        :disabled="!secondNoticeJulie"
        @click="secondNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("secondNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!retransmitJulie"
        @click="updateTicketDetails('RetransmitTicket')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
      >
        {{ $t("retransmit") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelJulie"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>

    <CancelTicketDialogJulie
      v-if="cancelTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="cancelTicketDialog"
      @cancelTicketSubmit="cancelTicketSubmit"
      @closeDialog="closeCancelTicketDialog"
      @cancelTicketSuccess="cancelTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <ExtendTicketDialogJulie
      v-if="extendTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="extendTicketDialog"
      @extendTicketSubmit="extendTicketSubmit"
      @closeExtendDialog="closeExtendTicketDialog"
      @extendTicketSuccess="extendTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <SecondNoticeDialogJulie
      v-if="secondNoticeTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="secondNoticeTicketDialog"
      @secondNoticeTicketSubmit="secondNoticeTicketSubmit"
      @closeSecondNoticeTicketDialog="closeSecondNoticeTicketDialog"
      @secondNoticeTicketSuccess="secondNoticeTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import EditTicket from "@/mixins/EditTicket";
import TicketSecondaryFunctions from "@/mixins/TicketSecondaryFunctions";
export default {
  mixins: [EditTicket, TicketSecondaryFunctions],
  name: "TickDetails",
  components: {
    CancelTicketDialogJulie: () => import("./CancelTicketDialogJulie.vue"),
    ExtendTicketDialogJulie: () => import("./ExtendTicketDialogJulie.vue"),
    SecondNoticeDialogJulie: () => import("./SecondNoticeDialogJulie.vue"),
    ErrorMessage: () => import("../../../../molecules/ErrorMessage.vue"),
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelJulie: false,
    extendJulie: false,
    secondNoticeJulie: false,
    retransmitJulie: false,
    dialog: true,
    cancelTicketDialog: false,
    extendTicketDialog: false,
    secondNoticeTicketDialog: false,
    retransmitTicketDialog: false,
    ticketData: {},
    errorCode: "",
    errorMessage: "",
    isError: false,
  }),
  computed: {},
  async created() {
    this.moment = moment;
  },
  async mounted() {
    this.ticketData = Object.assign({}, this.ticketDetails);
    await this.checkSecondaryFunctionsEligibility();
  },
  methods: {
    errorSubmitTicket(data) {
      this.errorCode = data.errorCode.toString();
      this.errorMessage = data.error;
      this.isError = true;
      console.log(data);
    },
    retransmitTicket() {
      this.retransmitTicketDialog = true;
    },
    closeRetransmitTicketDialog() {
      this.retransmitTicketDialog = false;
    },
    retransmitTicketSubmit() {
      this.retransmitTicketDialog = false;
    },
    async retransmitTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.retransmitTicketDialog = false;
      this.$emit(
        "retransmitTicketSuccess",
        retransmitTicketNumber,
        retransmitTicketRevision
      );
    },
    async updateTicketDetails(editType) {
      await this.updateTicket(this.ticketDetails, editType);
      this.$router.push("/createTicket").catch();
    },
    cancelTicket() {
      this.cancelTicketDialog = true;
    },
    closeCancelTicketDialog() {
      this.cancelTicketDialog = false;
    },
    cancelTicketSubmit() {
      this.cancelTicketDialog = false;
    },
    async cancelTicketSuccess(cancelTicketNumber, cancelTicketRevision) {
      this.cancelTicketDialog = false;
      this.$emit(
        "cancelTicketSuccess",
        cancelTicketNumber,
        cancelTicketRevision
      );
    },

    extendTicket() {
      this.extendTicketDialog = true;
    },
    closeExtendTicketDialog() {
      this.extendTicketDialog = false;
    },
    extendTicketSubmit() {
      this.extendTicketDialog = false;
    },
    async extendTicketSuccess(ticketNumber, ticketRevision) {
      this.extendTicketDialog = false;
      this.$emit("extendTicketSuccess", ticketNumber, ticketRevision);
    },

    secondNoticeTicket() {
      this.secondNoticeTicketDialog = true;
    },
    closeSecondNoticeTicketDialog() {
      this.secondNoticeTicketDialog = false;
    },
    secondNoticeTicketSubmit() {
      this.secondNoticeTicketDialog = false;
    },
    async secondNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.secondNoticeTicketDialog = false;
      this.$emit("secondNoticeTicketSuccess", ticketNumber, ticketRevision);
    },

    getNextWorkDay(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
